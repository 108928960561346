import React, { useState } from 'react';
import FormLabel from "components/FormLabel";
import Alert, {ALERT_TYPES} from "../../components/Alert";

function UserNewForm() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('password');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (alertType === ALERT_TYPES.SUCCESS) {
            return;
        }

        try {
            const response = await fetch(apiUrl + '/api/v1/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });

            const data = await response.json();

            if (response.ok && !data.error) {
                setAlertMessage('Your account has been created. More information will be provided as the project proceeds.');
                setAlertType(ALERT_TYPES.SUCCESS);
            } else {
                setAlertMessage(data.error);
                setAlertType(ALERT_TYPES.ERROR);
            }
        }  catch (error) {
            setAlertMessage('An error occurred. Please try again later.');
            setAlertType(ALERT_TYPES.ERROR);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <FormLabel forAttr="email" label="Email"/>
                <input type="email" value={email} name="email" placeholder="Enter your email" required
                       onChange={(e) => setEmail(e.target.value)}
                       className="px-4 py-2 rounded focus-ring"/>
            </div>
            <div className="mb-4">
                <FormLabel forAttr="password" label="Password"/>
                <input type="password" value={password} name="password" placeholder="Enter your password" required
                       onChange={(e) => setPassword(e.target.value)}
                       className="px-4 py-2 rounded focus-ring"/>
            </div>
            <div className="mb-4 mt-6">
                {alertType !== ALERT_TYPES.SUCCESS && <button type="submit"
                     className="text-white px-6 py-2 rounded focus-ring button-submit bg-primary">Sign Up
                </button> }
            </div>
            {alertMessage && <Alert message={alertMessage} type={alertType} /> }
        </form>
    );
}

export default UserNewForm;