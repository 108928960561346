import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LayoutStandard from './pages/LayoutStandard.js';
import Home from './pages/home/Home.js';
import NotFound404 from './pages/NotFound404.js';
import UserNew from './pages/user/UserNew.js';
import UserVerifyEmail from './pages/user/UserVerifyEmail.js';
import ProfileEdit from './pages/profile/ProfileEdit.js';

import './App.css';

function App() {
  return (
      <Router>
          <Routes>
              {/* Routes with Layout */}
              <Route element={<LayoutStandard />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/join" element={<UserNew />} />
                  <Route path="/verify/:token" element={<UserVerifyEmail />} />
                  <Route path="/profile/edit" element={<ProfileEdit />} />
                  <Route path="*" element={<NotFound404 />} />
              </Route>
          </Routes>
      </Router>
  );
}

export default App;
